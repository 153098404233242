import Authorization from "@/app/env.authorization.js";
import axios from "axios";
import qs from "qs";

const uri = process.env.VUE_APP_OCPP_API_SERVER;
// const h = undefined;
// const axiosInstance = axios.create({
//   baseURL: uri,
//   headers: Authorization.getAuthorizationHeader(h),
//   timeout: 1000,
// });

let urlUpdate = (element) => {
  // http를 -> https로 바꾸니까 됨
  let urls = element._links.self.href;
  urls = [urls.slice(0, 4), "s", urls.slice(4)].join("");
  return urls;
};

const API = {
  headers(h) {
    return Authorization.getAuthorizationHeader(h);
  },

  /**
   * query string parameters
   * axios에서 request params에 담음
   */
  params(vue_data_table_options) {
    let params = {};

    if (vue_data_table_options["page"] != undefined) {
      params.page = vue_data_table_options["page"] - 1;
    }
    if (vue_data_table_options["itemsPerPage"] != undefined) {
      params.size = vue_data_table_options["itemsPerPage"];
    }

    let sort = [];
    if (
      Array.isArray(vue_data_table_options["sortBy"]) &&
      Array.isArray(vue_data_table_options["sortDesc"])
    ) {
      for (let i = 0; i < vue_data_table_options["sortBy"].length; i++) {
        const property = vue_data_table_options["sortBy"][i];
        const direction = vue_data_table_options["sortDesc"][i]
          ? "desc"
          : "asc";
        sort.push(property + "," + direction);
      }
    }
    params.sort = sort;
    return params;
  },

  /**
   * body
   * axios에서 request data에 담음
   */
  data(vue_data_table_options) {
    if (vue_data_table_options["query"] != undefined) {
      return vue_data_table_options["query"];
    }
    return vue_data_table_options;
  },

  users: {
    authority(h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/authority`,
        method: "GET",
      });
    },
    create(e, h) {
      const content = typeof e == "string" ? { username: e } : e;
      return axios({
        headers: API.headers(h),
        url: `${uri}/users`,
        method: "POST",
        data: content,
      });
    },
    read(e, h) {
      const username = typeof e == "string" ? e : e.username;
      return axios({
        headers: API.headers(h),
        url: `${uri}/users/${username}`,
        method: "GET",
      });
    },
  },

  managements: {
    /** 충전소 기본정보 - 운영기관 리스트 받아오기 */
    initOperator(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/management/init`,
        method: "GET",
        data: element,
      });
    },

    create(element, h) {
      window.location.reload(); // 충전소 생성하자마자 페이지 reload
      return axios({
        headers: API.headers(h),
        url: `${uri}/managements`,
        method: "POST",
        data: element,
      });
    },

    update(element, h) {
      const id = typeof element == "string" ? element : element.id;
      return axios({
        headers: API.headers(h),
        url: `${uri}/managements/${id}`,
        method: "PATCH",
        data: element,
      });
    },

    search(vue_data_table_options, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/managements/search`,
        method: "POST",
        data: API.data(vue_data_table_options),
        params: API.params(vue_data_table_options),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      }).catch(() => alert("데이터를 불러올 수 없습니다."));
    },

    revisions: {
      search(vue_data_table_options, h) {
        const id = vue_data_table_options.query.management;
        return axios({
          headers: API.headers(h),
          url: `${uri}/managements-revisions/${id}`,
          method: "GET",
          params: API.params(vue_data_table_options),
          paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: "repeat" });
          },
        });
      },
      // 안 쓰는 api
      read(path, h) {
        return axios({
          headers: API.headers(h),
          url: `${uri}/managements-revisions/${path}`,
          method: "GET",
        });
      },
    },
  },

  equipments: {
    // 안 쓰는 api
    read(element, h) {
      const id = typeof element == "string" ? element : element.id;
      return axios({
        headers: API.headers(h),
        url: `${uri}/equipments/${id}`,
        method: "GET",
      });
    },

    update(element, h) {
      const id = typeof element == "string" ? element : element.id;
      return axios({
        headers: API.headers(h),
        url: `${uri}/equipments/${id}`,
        method: "PATCH",
        data: element,
      });
    },

    search(vue_data_table_options, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/equipments/search`,
        method: "POST",
        data: API.data(vue_data_table_options),
        params: API.params(vue_data_table_options),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },

    revisions: {
      search(vue_data_table_options, h) {
        const id = vue_data_table_options.query.equipment;
        return axios({
          headers: API.headers(h),
          url: `${uri}/equipments-revisions/${id}`,
          method: "GET",
          params: API.params(vue_data_table_options),
          paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: "repeat" });
          },
        });
      },
      // 안 쓰는 api
      read(path, h) {
        return axios({
          headers: API.headers(h),
          url: `${uri}/equipments-revisions/${path}`,
          method: "GET",
        });
      },
    },
  },

  managers: {
    // 다른 사람한테 충전소 공유하기
    create(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/managers`,
        method: "POST",
        data: element,
      });
    },

    // 충전소 공유받기
    update(element, h) {
      const id = typeof element == "string" ? element : element.id;
      return axios({
        headers: API.headers(h),
        url: `${uri}/managers/${id}`,
        method: "PATCH",
        data: {},
      });
    },

    // 충전소 공유된 사람 삭제
    delete(element, h) {
      return axios({
        headers: API.headers(h),
        url: element._links.self.href,
        // url: urlUpdate(element),
        method: "DELETE",
      });
    },

    // 충전소 공유된 사람 조회
    search(vue_data_table_options, h) {
      console.log(vue_data_table_options["query"]); // {management: '9d204849-2472-4c32-959b-4f8848ec7184'}
      return axios({
        headers: API.headers(h),
        url: `${uri}/managers/search`,
        method: "POST",
        data: API.data(vue_data_table_options),
        params: API.params(vue_data_table_options),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },
  },

  firmwares: {
    create(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/firmwares`,
        method: "POST",
        data: element,
      });
    },

    delete(id, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/firmwares/${id}`,
        method: "DELETE",
      });
    },

    search(vue_data_table_options, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/firmwares/search`,
        method: "POST",
        data: API.data(vue_data_table_options),
        params: API.params(vue_data_table_options),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },
  },

  prices: {
    create(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/prices`,
        method: "POST",
        data: element,
      });
    },

    update(element, h) {
      // http://dev-ocpp-api.ev-infra.com/prices/f5377489-775e-4b1c-9900-f85e1c265cc2
      console.log(element._links.self.href);
      // https://dev-ocpp-api.ev-infra.com/prices/f5377489-775e-4b1c-9900-f85e1c265cc2
      console.log(urlUpdate(element));
      return axios({
        headers: API.headers(h),
        url: element._links.self.href,
        // url: urlUpdate(element),
        method: "PATCH",
        data: element,
      });
    },

    delete(element, h) {
      return axios({
        headers: API.headers(h),
        url: element._links.self.href,
        // url: urlUpdate(element),
        method: "DELETE",
      });
    },

    search(vue_data_table_options, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/prices/search`,
        method: "POST",
        data: API.data(vue_data_table_options),
        params: API.params(vue_data_table_options),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },

    revisions: {
      search(vue_data_table_options, h) {
        const id = vue_data_table_options.query.price;
        return axios({
          headers: API.headers(h),
          url: `${uri}/prices-revisions/${id}`,
          method: "GET",
          params: API.params(vue_data_table_options),
          paramsSerializer: (p) => {
            return qs.stringify(p, { arrayFormat: "repeat" });
          },
        });
      },
      read(path, h) {
        return axios({
          headers: API.headers(h),
          url: `${uri}/prices-revisions/${path}`,
          method: "GET",
        });
      },
    },
  },

  events: {
    /** 기존 충전내역 조회 */
    search(vue_data_table_options, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/events/search`,
        method: "POST",
        data: API.data(vue_data_table_options),
        params: API.params(vue_data_table_options),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },

    /** new 충전내역 조회 (환경부 충전 누락 적용) */
    searchEvents(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/api/v1/event`,
        method: "POST",
        data: API.data(element),
        params: API.params(element),
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },

    /** new 충전내역 상세 조회 */
    searchEvent(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/api/v1/event/api-history`,
        method: "GET",
        params: element,
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },

    /** new 충전내역 환경부 재전송(미전송/실패일때만) */
    retryEvent(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/api/v1/event/api-retry`,
        method: "POST",
        data: API.data(element),
      });
    },
  },

  inquiry: {
    create(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/inquiry`,
        method: "POST",
        data: element,
      });
    },
  },

  inquiries: {
    /** 1:1문의글 리스트 조회 */
    search(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/inquiries/search`,
        method: "POST",
        params: API.params(element),
        data: element,
      });
    },

    /** 1:1문의글 답변 등록 */
    createReply(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/replies`,
        method: "POST",
        data: element,
      });
    },

    /** 1:1문의글 답변 조회 */
    searchReply(element, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/replies/search`,
        method: "POST",
        data: element,
      });
    },

    /** 1:1문의글 답변 삭제 */
    deleteReply(element, replyId, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/replies/${replyId}`,
        method: "PATCH",
        data: element,
      });
    },

    /** 1:1문의글 답변 수정 */
    updateReply(element, replyId, h) {
      return axios({
        headers: API.headers(h),
        url: `${uri}/replies/${replyId}`,
        method: "PATCH",
        data: element,
      });
    },
  },
};

export default API;
