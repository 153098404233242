import axios from "axios";

const oauth2 = process.env.VUE_APP_AUTHORIZATION_SERVER;

const authorizationKey = process.env.VUE_APP_AUTHORIZATION_STORAGE;
const tokenKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_TOKEN;
const userinfoKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_USERINFO;
const usernameKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_USERNAME;
const nicknameKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_NICKNAME;
const providerKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_PROVIDER;
const emailKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_EMAIL;
const adminKey = process.env.VUE_APP_AUTHORIZATION_ADMIN;
const adminFlagKey = process.env.VUE_APP_AUTHORIZATION_ADMIN_FLAG;

export default {
  // canAuthorization(requestParams){
  //   return requestParams['access_token'] != null
  //       && requestParams['id_token'] != null
  //       && requestParams['token_type'] != null
  //       && requestParams['username'] != null
  // },
  // isAuthorization(){
  //     return localStorage.getItem(authorizationKey) != null;
  // },
  // getUsername(){
  //   return localStorage.getItem(usernameKey);
  // },
  getAuthorization() {
    return localStorage.getItem(authorizationKey);
  },

  getAuthorizationHeader(headers) {
    if (headers == undefined) {
      return { Authorization: localStorage.getItem(authorizationKey) };
    } else {
      headers["Authorization"] = localStorage.getItem(authorizationKey);
      return headers;
    }
  },

  getAuthorizationData() {
    return {
      provider: localStorage.getItem(providerKey),
      token: localStorage.getItem(tokenKey),
      nickname: localStorage.getItem(nicknameKey),
      username: localStorage.getItem(usernameKey),
      userinfo: localStorage.getItem(userinfoKey),
    };
  },

  isAdmin() {
    if ("true" == adminFlagKey) {
      return localStorage.getItem(usernameKey) == adminKey;
    } else {
      return true;
    }
  },

  isAdminId() {
    if (adminKey === localStorage.getItem(usernameKey)) {
      return true;
    } else {
      return false;
    }
  },

  login(provider, callbackUrl) {
    console.log("login", provider, callbackUrl);

    let moveTo = `${oauth2}/oauth2/login`;
    moveTo = moveTo + "?provider=" + provider;
    moveTo = moveTo + "&callback=" + callbackUrl;
    window.location.href = moveTo;
  },

  logon(queryParam, successFunc, failureFunc) {
    console.log(queryParam);
    if (
      queryParam["access_token"] == null ||
      queryParam["id_token"] == null ||
      queryParam["token_type"] == null ||
      queryParam["username"] == null
    ) {
      failureFunc();
      return;
    }

    // token_type=Bearer, id_token=eyJraWQiOiJodHRwczpcL1wvb2F1dGgyLmV2....
    const authorization = queryParam.token_type + " " + queryParam.id_token;
    axios({
      url: queryParam.id_token_user_info, // id_token_user_info=http://localhost:8081/user/info
      headers: {
        Authorization: authorization,
      },
    })
      .then((r) => {
        let provider = "";
        let nickname = "";
        try {
          console.log(localStorage.getItem(providerKey));
          console.log(r);
          const types = r.data.claims.authorities;
          if (types.indexOf("GOOGLE") == 0) {
            console.log(types);
            provider = "Google";
            nickname = r.data.claims.name;
          } else if (types.indexOf("FACEBOOK") == 0) {
            provider = "Facebook";
            nickname = r.data.claims.name;
          } else if (types.indexOf("KAKAO") == 0) {
            provider = "Kakao";
            nickname = r.data.claims.kakao_account.profile.nickname;
          } else if (types.indexOf("NAVER") == 0) {
            provider = "Naver";
            nickname = "...";
          }
        } catch {
          provider = "???";
          nickname = "???";
        }

        localStorage.setItem(emailKey, r.data.claims.email); // 충전소 공유 리스트 본인 확인용
        localStorage.setItem(providerKey, provider);
        localStorage.setItem(tokenKey, queryParam.id_token);
        localStorage.setItem(nicknameKey, nickname);
        localStorage.setItem(usernameKey, queryParam.username);
        localStorage.setItem(userinfoKey, queryParam.id_token_user_info);
        localStorage.setItem(authorizationKey, authorization);

        successFunc({
          provider: localStorage.getItem(providerKey),
          token: localStorage.getItem(tokenKey),
          nickname: localStorage.getItem(nicknameKey),
          username: localStorage.getItem(usernameKey),
          userinfo: localStorage.getItem(userinfoKey),
        });
      })
      .catch(() => {
        failureFunc();
      });
  },

  logout(successFunc, failureFunc) {
    axios({
      url: `${oauth2}/oauth2/logout`,
    })
      .then(() => {
        console.log(localStorage.getItem(providerKey));
        localStorage.clear();
        console.log(localStorage.getItem(providerKey));
        successFunc();
      })
      .catch(() => {
        localStorage.clear();
        failureFunc();
      });
  },
};
